import React from "react"
import Layout from "../layouts/Base"
import Question from "../components/Question"
import "../style/main.scss"

export default function Questions() {
	
	return (
		<Layout>
			<Question q={4} />
		</Layout>
	)
}
